<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Shared Funds with Negative Balance</span>
    </v-card-title>
      <v-data-table
        :headers="sharedFundsHeaders"
        :items="sharedFundItems"
        class="elevation-1"
        :hide-default-footer="true"
      >
      </v-data-table>
  </v-card>
</template>

<script>

export default {
  name: 'HandleWarCreateNewFYAdditionalData',
  props: {
    fundsWithNegativeBalance: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    sharedFundsHeaders: [
      { text: 'FY', value: 'fiscalYear' },
      { text: 'Fund Number', value: 'fund' },
    ],
  }),
  computed: {
    sharedFundItems() {
      const value = this.fundsWithNegativeBalance.length > 0 ? this.fundsWithNegativeBalance : [];
      return value;
    },
  },
  methods: {},
};
</script>
