<template>
  <v-dialog
    v-model="openCloseDialog"
    persistent
    max-width="50vw"
  >
    <v-card>
      <v-card-title class="justify-center">
        <span class="text-h5">Create New Fiscal Year Status</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <div v-if="isComponentStatusHasData">
            <v-simple-table dense
              class="create-fiscal-year-simple-table"
              id="createNewFiscalYearTable">
              <thead>
                <tr>
                  <th class="text-h5">
                    {{ statusText}}
                  </th>
                </tr>
              </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in componentStatusItems"
                    :key="'table-row-' + index"
                  >
                    <td>{{ item }}</td>
                  </tr>
                </tbody>
            </v-simple-table>
          </div>
          <div v-if="isWarrantSpecificAdditionalData">
            <HandleWarCreateNewFYAdditionalData
              :fundsWithNegativeBalance="sharedFundsNegativeBalanceArr" />
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="openCloseDialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import HandleWarCreateNewFYAdditionalData from '../../../views/common/createNewFiscalYear/HandleWarCreateNewFYAdditionalData.vue';

export default {
  name: 'HandleCreateNewFYAdditionalDataGrp',
  components: {
    HandleWarCreateNewFYAdditionalData,
  },
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    response: {
      type: Object,
      default: () => ({}),
    },
    programName: {
      type: String,
      default: () => (''),
    },
  },
  data: () => ({}),
  computed: {
    openCloseDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('openCloseDialog', value);
      },
    },
    isWarrantSpecificAdditionalData() {
      return ((this.response.additionalDataGroup || {})
        .sharedFundsWithNegativeBalArr || []).length > 0;
    },
    sharedFundsNegativeBalanceArr() {
      return (this.response.additionalDataGroup || {}).sharedFundsWithNegativeBalArr || [];
    },
    isComponentStatusHasData() {
      return (this.response.componentStatusArr || []).length > 0;
    },
    componentStatusItems() {
      return this.response.componentStatusArr || [];
    },
    statusText() {
      return this.response.status || '';
    },
  },
  methods: {},
};
</script>
